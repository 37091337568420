<template>
  <en-table-layout
    :tableData="tableData.data"
    :loading="loading"
  >
    <template slot="toolbar">
      <div class="toolbar-title">登录日志</div>
    </template>

    <template slot="table-columns">
      <el-table-column label="登录时间">
        <template slot-scope="scope">{{ scope.row.create_date | unixToDate }}</template>
      </el-table-column>
      <el-table-column prop="account_name" label="登陆人员"/>
      <el-table-column prop="ip" label="登陆IP"/>
    </template>

    <el-pagination
      v-if="tableData"
      slot="pagination"
      @size-change="handlePageSizeChange"
      @current-change="handlePageCurrentChange"
      :current-page="tableData.page_no"
      :page-sizes="MixinPageSizes"
      :layout="MixinTableLayout"
      :page-size="tableData.page_size"
      :total="tableData.data_total">
    </el-pagination>
  </en-table-layout>
</template>

<script>
import {mapGetters} from 'vuex';
import * as API_SiteMenu from '@/api/siteMenu';
import EnTableLayout from '../../../../ui-components/TableLayout/src/main';

export default {
  name: 'loginJournal',
  components: {EnTableLayout},
  computed: {
    ...mapGetters(['user'])
  },
  data() {
    return {
      /** 列表loading状态 */
      loading: false,

      /** 列表数据 */
      tableData: {},
      /** 分页 */
      page_no: 1,
      page_size: 20,
      data_total: ''
    }
  },
  mounted() {
    this.GET_AccountLog();
  },
  methods: {
    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.page_size = size
      this.GET_AccountLog()
    },
    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.page_no = page
      this.GET_AccountLog()
    },
    /** 获取子级楼层列表 */
    GET_AccountLog() {
      this.loading = true;
      /** 列表参数 */
      let params = {
        page_no: this.page_no,
        page_size: this.page_size,
        account_id: this.user.uid,   // 账户id
        account_type: 1, // 账户类型 0:前端会员 1:商家 2：供应商 3：企业，4大平台
        type: 1,         // 日志类型，1登录日志，2操作日志，3其他日志
      };
      // 如果是企业跳站点（0 代表企业登录   1代表企业跳转商家），设置成 3
      this.MixinParentEnterpriseInfo().then(res => {
        if (res.parentLogin) {
          params.account_type = 3;
        }
        API_SiteMenu.getAccountLog(params).then(res => {
          this.loading = false;
          this.tableData = res;
        }).catch(() => {
          this.loading = false
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
